import React from 'react';
import Layout from '@components/Layouts/Layout';
import { ProductsRoot } from '@components/Blocks/ProductsRoot';
import MobileApps from '@components/Blocks/MobileApps';
import { Services } from '@components/Blocks/Services';
import { RecommendationsGroupped } from '@components/Blocks/RecommendationsGroupped';
import { SchemaOrgOrganization } from '@components/Layouts/SchemaOrg/SchemaOrgOrganization';
import { DesktopBanner, MobileBanner } from '@components/Blocks/Banners';
import SEO from '@components/Layouts/SEO';

import { useBanners } from '@hooks/useBanners';

import ProductsTop from '@components/Blocks/ProductsTop';
import { AboutProject } from '@components/Blocks/AboutProject';
import { Blog } from '@components/Blocks/Blog/Blog';

import { PromoBlock } from '@components/Blocks/PromoBlock';
import { ReviewsList } from '@components/Blocks/ReviewsList';

export function Head() {
  return (
    <>
      <SEO title=" Фотокнига на заказ: изготовление и печать фотокниг в Москве с доставкой по России" />
      <SchemaOrgOrganization />
    </>
  );
}

function IndexPage() {
  const desktopItems = useBanners();
  const mobileItems = useBanners(true);

  return (
    <Layout>
      <DesktopBanner items={desktopItems} />
      <MobileBanner items={mobileItems} />
      <ProductsRoot />
      <ProductsTop />
      <Services header="Почему мы?" />
      <PromoBlock
        promoImage="szv-photo.jpg"
        promoTitle="Соберем фотокнигу за вас"
        promoText="Создавайте новые счастливые воспоминания, а наши дизайнеры позаботятся о том, чтобы сохранить их красиво"
        promoLink="/design-photobook"
      />
      <RecommendationsGroupped />
      {/* TODO: replace with PromoBlock */}
      <AboutProject />
      <Blog />
      <ReviewsList />
      <MobileApps />
    </Layout>
  );
}

export default IndexPage;
